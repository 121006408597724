import React from "react";
import "./Homescreen.css";

export default function TrustedComoany() {
  return (
    <div className="trusted-company-container">
      <h2>Trusted by huge organizations</h2>

      <div className="slide-container">
        <div className="slide-row">
          <div className="home-mentor-sec-wrap">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/StudentDiwanLogo.8fcd85d1ecdb3d8b889a.png"
                  alt="mentor-img"
                />
              </div>
            </div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/edobo-logo-800x293-2.jpg"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/fundly.png"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/65114d62c0c8b53ff6b1b01b_color_transparent-1.png"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/nido_automation_in_logo.jpeg"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/images-1.png"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>

          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/images.png"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
        </div>

        <div className="slide-row">
          <div className="home-mentor-sec-wrap">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/StudentDiwanLogo.8fcd85d1ecdb3d8b889a.png"
                  alt="mentor-img"
                />
              </div>
            </div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/edobo-logo-800x293-2.jpg"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/fundly.png"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/65114d62c0c8b53ff6b1b01b_color_transparent-1.png"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/nido_automation_in_logo.jpeg"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/images-1.png"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>

          <div className="home-mentor-sec-wrap redirect-mentor">
            <div className="home-mentor-sec">
              <div>
                <img
                  height="80"
                  width="80"
                  src="https://xpert.works/wp-content/uploads/2024/10/images.png"
                  alt="mentor-img"
                />
              </div>
            </div>
            <div className="home-mentor-name"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
